import React, { useEffect, useMemo } from 'react';
import { Layout, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { usePostHog } from 'posthog-js/react';
import MyMenu from './Menu';
import MyAppBar from './AppBar';
import MySidebar from './Sidebar';
import PermissionUtils from '../common/permissionUtils';

const useLayoutStyles = makeStyles({
  appFrame: {
    marginTop: 0,
  },
  content: {
    marginTop: 77,
    margin: '77px 36px 0 36px',
  },
});

const MyLayout = (props) => {
  const classes = useLayoutStyles();
  const permissionBase = usePermissions();
  const permissions = useMemo(() => new PermissionUtils(permissionBase), [permissionBase]);

  const posthog = usePostHog();
  useEffect(() => {
    posthog?.identify(permissions.getId());
  }, [posthog, permissions]);
  return (
    <Layout
      {...props}
      classes={classes}
      appBar={MyAppBar}
      menu={MyMenu}
      sidebar={MySidebar}
    />
  );
};

export default MyLayout;
